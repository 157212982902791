$('document').ready(function(){

	$('.open-menu').click(function(){
		$('.sf-menu-mobile').slideDown('slow');;
	});

	$('.close-menu').click(function(){
		$('.sf-menu-mobile').slideUp('slow');
	});
});
